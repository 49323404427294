@import url('~mod/PulseAnimation.scss');

.TableLoadingPlaceholder_TextLine {
  height: 10px;
  margin: 10px;
  animation: PulseAnimation 1s infinite ease-in-out;
}

.TableLoadingPlaceholder_Cell {
  &:first-child {
    width: 20%;
  }

  &:last-child {
    width: 30%;
  }
}

.TableLoadingPlaceholder_TextLine {
  width: 60%;

  .TableLoadingPlaceholder_Row:last-child & {
    width: 80%;
  }
}
@keyframes PulseAnimation {
  0% {
    background-color: rgba(165, 165, 165, 0.1)
  }

  50% {
    background-color: rgba(165, 165, 165, 0.3)
  }

  100% {
    background-color: rgba(165, 165, 165, 0.1)
  }
}
.InputWithSuggestions {
  position: relative;
}

.InputWithSuggestions_Input {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: transparent;
  border: 0;
  box-shadow: none;
  padding: 0.375rem 0.75rem;
  width: 100%;
  outline: none;
}

.InputWithSuggestions_Suggestions {
  position: absolute;
  top: 100%;
  left: -1px;
  right: -1px;
  background: #fff;
  color: #495057;
  margin-bottom: 1rem;
  padding: 0;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  z-index: 1;
  list-style: none;
}

.InputWithSuggestions__Focused {
  & {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .InputWithSuggestions_Suggestions {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.InputWithSuggestions_SuggestionsItem {
  cursor: pointer;
  padding: 0.375rem 0.75rem;
}

.InputWithSuggestions_SuggestionsItem:hover {
  background: rgba(0, 0, 0, 0.075);
}

.InputWithSuggestions_SuggestionsItem:not(:first-child) {
  border-top: 1px solid #dee2e6;
}